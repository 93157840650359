/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import "./style.css";
import { emailVerification, getUser, verifyEmail } from "../../Apis/UserApi";
import { useNavigate } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import ROUTES from "../../Config/Routes";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout";
import Vector from "../../Assets/Images/Vector.svg";
import Cookies from "js-cookie";
import { setUserDetails } from "../../Redux/Action/User";
import { useDispatch, useSelector } from "react-redux";
import GlobalVariabels from "../../Utils/GlobalVariabels";
import { PublicEventApi } from "../../Apis/PublicApi";
import { showMessageWithCloseIcon } from "../../Utils/Reusables";
import { identify } from "../../Utils/Analytics";
import JivoChat from "../../Layouts/BlankHeaderLayout/JivoChat";
let currentEnv = GlobalVariabels.NODE_ENV;

const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [routeLoader, setRouteLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setRouteLoader(true);
    getUser()
      .then(async (profileResponse) => {
        identify(profileResponse);
        Cookies.set("user", JSON.stringify(profileResponse));
        await setUserDetails(profileResponse, dispatch);
        if (profileResponse?.registrationStep === "COMPLETED") {
          navigate(ROUTES.VERIFICATION);
        } else {
          setRouteLoader(false);
        }
      })
      .catch((error) => {
        console.log(error, "Error");
        setRouteLoader(false);
      });
    if (user?.registrationStep !== "COMPLETED") {
      emailVerification()
        .then(async (EmailVerificationResponse) => {
          setRouteLoader(false);
          if (EmailVerificationResponse?.verificationToken) {
            Cookies.set(
              "verificationToken",
              EmailVerificationResponse?.verificationToken
            );
          }
        })
        .catch((error) => {
          console.log("email verificatiopn error", error);
        });
    }
  }, []);

  const handleResendEmail = async () => {
    setLoader(true);
    emailVerification()
      .then(async (EmailVerificationResponse) => {
        Cookies.set(
          "verificationToken",
          EmailVerificationResponse?.verificationToken
        );
        showMessageWithCloseIcon(
          "We have sent verification email please check!"
        );
        setLoader(false);
        navigate(ROUTES.EMAIL_VERIFICATION);
      })
      .catch((error) => {
        console.log("email verification error", error);
        setLoader(false);
      });
  };

  const handleVerifyEmail = () => {
    verifyEmail({
      verificationToken: Cookies.get("verificationToken"),
    })
      .then((verifyEmailResponse) => {
        if (verifyEmailResponse?.token) {
          Cookies.set("auth_inv_token", verifyEmailResponse?.token);
          setLoader(false);
          navigate(ROUTES.VERIFICATION);

          PublicEventApi("emailConfirm");
        }
      })
      .catch((error) => {
        console.log("verify email", error);
        setLoader(false);
      });
  };

  return (
    <AuthLayout>
      {routeLoader === false ? (
        <div
          className="sb-onboarding-form-container"
          style={{ padding: "80px 148px" }}
        >
          <div className="sb-flex-column-item-center  mb-28">
            <img src={Vector} alt="email" className="kl-checkcircle" />
          </div>
          <div className="mb-28">
            <p className="kl-title m-0">Verify your email</p>

            <p className="kl-subtitle mt-10">
              A verification link was sent to your email{" "}
              <strong> {user?.email} </strong>. Please check your inbox and
              verify your account by clicking on the provided link.
            </p>
          </div>
          <div className="mb-28">
            <p className="kl-subtitle">
              Did not receive a verification email? <br /> If you don't see an
              email within 15 minutes, check your spam folder or try by clicking
              “Resend Email” button.
            </p>
          </div>
          <div className="sb-TwoFa-actions">
            <ButtonDefault
              title="Resend Email"
              block={true}
              loading={loader}
              onClick={handleResendEmail}
              id="btn-resend-email"
            />
          </div>

          {currentEnv === "DEV" && (
            <div className="mt-10">
              <p
                className="fp-link cursor-pointer"
                onClick={handleVerifyEmail}
                style={{ display: "inline" }}
              >
                Verify Email
              </p>
              <p style={{ color: "#ddd", display: "inline" }}>
                {" "}
                (For testing purpose)
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 100,
            }}
            spin
          />
        </div>
      )}
      {/* <SupportChatButton /> */}
      <JivoChat user={user} />
    </AuthLayout>
  );
};

export default EmailVerificationPage;
