import { setTranche, setCapitals } from "../Reducer/investor";

export const setTrancheResponse = (data, dispatch) => {
    dispatch(setTranche(data));
};

export const setCapitalRequests = (data, dispatch) => {
    dispatch(setCapitals(data));
};

