import axios from "axios";
import { notification } from "antd";
import { ErrorResponse } from "./ErrorResponse.js";
import GlobalVariabels from "../Utils/GlobalVariabels.js";
import { countries } from "countries-list";
import Cookies from "js-cookie";

export const apiHandler = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    const Headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }

    const data = {
      method,
      url: baseURL,
      headers: Headers,
      data: JSON.stringify(requestBody),
    };

    return axios(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          Cookies.remove("auth_inv_token");
          window.location.href = "/login";
        }
        else if (error?.response?.status === 524) {
          notification.error({
            type: "info",
            description:
              "We encountered an issue with your subscription. Please try again or reach out to us at sales@kilde.sg or +65 6950 7768 for assistance.",
          });
        }
        else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Oops! Something happened.",
            description:
              "We're on it! If this continues, please contact support at sales@kilde.sg.",
          });
        } else if (
          error?.response?.data?.error ===
          "Account not found for given current account number"
        ) {
          notification.error({
            type: "error",
            message: "Account Not Found",
            description:
              "No account was found for the provided current account number. Please verify the account number and try again.",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const commitApiHandler = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    const Headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }

    const data = {
      method,
      url: baseURL,
      headers: Headers,
      data: JSON.stringify(requestBody),
    };

    return axios(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          Cookies.remove("auth_inv_token");
          window.location.href = "/login";
        }
        else if (error?.response?.status === 524 || error?.response?.data?.status === 524 || error?.response?.data?.status === 520 || error?.response?.status === 520 || error?.response?.status === 504) {
          notification.error({
            type: "info",
            description:
              "We're processing your investment, which may take a little longer than usual due to its substantial size.Please refresh the page in a few minutes for an update.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
        else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Oops! Something happened.",
            description:
              "We're on it! If this continues, please contact support at sales@kilde.sg.",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const dowloadReports = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }
    return axios
      .post(baseURL, requestBody, { responseType: "arraybuffer" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          window.location.href = "/login";
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Oops! Something happened.",
            description:
              "We're on it! If this continues, please contact support at sales@kilde.sg.",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const TrancheAcceptancedowload = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }
    return axios({
      method: method,
      url: baseURL,
      data: requestBody,
      responseType: "arraybuffer",
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let err;
        if (error?.response?.data?.status === 403) {
          window.location.href = "/login";
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Oops! Something happened.",
            description:
              "We're on it! If this continues, please contact support at sales@kilde.sg.",
          });
        } else if (
          Object.keys(error?.response?.data?.fieldErrors)?.length > 0 ||
          error.response?.data?.fieldErrors?.length > 0
        ) {
          err = error?.response?.data?.fieldErrors;
        } else {
          err = error?.response?.data?.errors;
        }
        ErrorResponse(err);
        return error?.response?.data;
      });
  } catch (error) {
    return error;
  }
};

export const withoutTokenApiHandler = async (
  method,
  url,
  requestBody = {},
  page = null,
  limit = null
) => {
  try {
    const Headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    let baseURL = GlobalVariabels.NODE_ENV === "DEV" ? `${url}` : `${url}`;

    if (page) {
      baseURL = `${baseURL}?page=${page}&limit=${limit}`;
    }

    const data = {
      method,
      url: baseURL,
      headers: Headers,
      data: JSON.stringify(requestBody),
    };
    return axios(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error?.response?.data?.error === "no investor found") {
          notification.error({
            type: "error",
            message: "Oops! User not found",
            description:
              "We couldn't find an account associated with this email. Please double-check your email address or create a new account.",
          });
        } else if (error?.response?.data?.error === "wrong credentials") {
          notification.error({
            type: "error",
            message: "Invalid credentials",
            description: "Invalid username or password. Please try again",
          });
        } else if (error?.response?.data?.error === "Too many attempts") {
          notification.error({
            type: "error",
            message: "Please try again later.",
            description:
              "You have exceeded the maximum number of attempts. Please wait for some time before trying again",
          });
        } else if (error?.response?.data?.error === "Internal Server Error") {
          notification.error({
            type: "error",
            message: "Oops! Something happened.",
            description:
              "We're on it! If this continues, please contact support at sales@kilde.sg.",
          });
        } else if (error?.response?.data) {
          ErrorResponse(error?.response?.data?.fieldErrors);
          return error?.response?.data;
        } else {
          return error;
        }
      });
  } catch (error) {
    return error;
  }
};

export const getCountries = () => {
  const allCountries = Object.keys(countries).map((k) => ({
    key: k,
    value: countries[k].name,
  }));
  allCountries.sort((a, b) => a.value.localeCompare(b.value));
  const singaporeIndex = allCountries.findIndex((item) => item.key === "SG");
  if (singaporeIndex !== -1) {
    const singaporeItem = allCountries[singaporeIndex];
    allCountries.splice(singaporeIndex, 1);
    allCountries.unshift(singaporeItem);
  }

  return allCountries;
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader?.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader?.result;
      resolve(baseURL);
    };
  });
};

export const checkStepStatus = (data, step) => {
  const StepStatus = data?.includes(step);
  return StepStatus;
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export function get_ga_clientid() {
  var cookie = {};
  document.cookie.split(";").forEach(function (el) {
    var splitCookie = el.split("=");
    var key = splitCookie[0].trim();
    var value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie["_ga"].substring(6);
}

export function redirectToVue(appToRedirect) {
  let originalCookies = document.cookie;
  function parseCookies(cookiesString) {
    return cookiesString.split("; ").reduce(function (acc, cookie) {
      var parts = cookie.split("=");
      acc[parts[0]] = parts[1];
      return acc;
    }, {});
  }

  function setCookies(cookiesObject, domain) {
    for (var cookieName in cookiesObject) {
      if (cookiesObject.hasOwnProperty(cookieName)) {
        document.cookie =
          cookieName +
          "=" +
          cookiesObject[cookieName] +
          "; domain=" +
          domain +
          "; path=/;";
      }
    }
  }

  var parsedCookies = parseCookies(originalCookies);
  setCookies(parsedCookies, ".kilde.sg");
  if (appToRedirect === "vue") {
    window.location.href = `${GlobalVariabels.RIDIRECT_TO_VUE_URL}/redirect`;
  } else if (appToRedirect === "react") {
    window.location.href = `${GlobalVariabels.API_BASE_URL}/dashboard`;
  } else {
    window.location.href = `${GlobalVariabels.RIDIRECT_TO_VUE_URL}/redirect`;
  }
}

export const getPasswordStrength = (password) => {
  if (password?.length < 6) {
    return 0;
  } else if (password?.length < 8) {
    return 30;
  } else if (
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    password?.length >= 10 &&
    /[0-9]/.test(password) &&
    /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password)
  ) {
    return 100;
  } else {
    return 60;
  }
};

export const britishFormatDate = (inputDate) => {
  const date = new Date(inputDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const saveUTMUrlToCookie = () => {
  const setCookie = (name, value, days) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(
      value
    )}; expires=${expires}; path=/`;
  };
  const urlParams = new URLSearchParams(window.location.search);

  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");

  if (utmSource && utmMedium && utmCampaign) {
    setCookie("utm_source", utmSource, 7);
    setCookie("utm_medium", utmMedium, 7);
    setCookie("utm_campaign", utmCampaign, 7);
  }
};

export const generateUTMURL = (hj) => {
  const getCookie = (name) => {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].trim().split("=");

      if (cookiePair[0] === name) {
        return decodeURIComponent(cookiePair[1]);
      }
    }

    return null;
  };

  const utmSource = getCookie("utm_source");
  const utmMedium = getCookie("utm_medium");
  const utmCampaign = getCookie("utm_campaign");

  const baseUrl = window.location.origin + window.location.pathname;

  let UTMUrl =
    baseUrl +
    `?utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_source=${utmSource}&hj=${hj}`;

  return UTMUrl;
};
