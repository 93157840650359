import React from "react";

export const walletWithdrawRequestListTooltipContent = (
  <div style={{ fontSize: 12 }}>
    "Processed" indicates that your payout request has been handled by kilde's
    bank platform, so your funds are on the way. However, the actual crediting
    of the funds will depend on the receiving beneficiary bank.
  </div>
);

export const walletTooltipContent = (
  <div style={{ fontSize: 12 }}>
    Funds available in your account to be invested in debentures. Please check
    the wallet section to learn how to transfer funds to your account.
  </div>
);

export const TATooltipContent = (
  <div style={{ fontSize: 12 }}>
    Formula: Total assets (=) Wallet (+) Committed (+) Investments
  </div>
);

export const committedTooltipContent = (
  <div style={{ fontSize: 12 }}>
    Amount committed to the purchase of the debenture(s). All settlements are
    fulfilled via the Escrow Accounts of the Issuer and investors in the
    platform on a weekly basis.
  </div>
);

export const investmentsTooltipContent = (
  <div style={{ fontSize: 12 }}>
    Formula: Investments (=) Outstanding Principle (+) Pending Interest
    Payments. Outstanding Principal is the Original sum of money put into
    Investment(s) that have already been settled. Hence, the sum of the face
    value(s) of your Outstanding Investment(s). The Pending Interest Payments is
    the sum of the scheduled interest payments that is in delay.
  </div>
);

export const netAverageProfitabilityTooltip = (
  <div style={{ fontSize: 12 }}>
    Sum of all coupon payments, repaid principal and potential late payment fees
    (when applicable) net of service charges and potential charge offs (when
    applicable) divided by the sum of all the invested money. The figure is
    presented in an annualized format.
  </div>
);

export const interestAccruedTooltip = (
  <div style={{ fontSize: 12 }}>
    Sum of paid interest to the investor related to the outstanding
    investment(s).
  </div>
);

export const interestForecastTooltip = (
  <div style={{ fontSize: 12 }}>
    Sum of future schedule interest payments to the investor of the outstanding
    investment(s).
  </div>
);

export const feesExpenseTooltip = (
  <div style={{ fontSize: 12 }}>
    Total amount paid to the platform related to the outstanding investment(s).
  </div>
);

export const accruedInterestExpenseTooltip = (
  <div style={{ fontSize: 12 }}>
    Expense related to the sum of the daily interest payments accumulated since
    the last coupon payment until the subsequent settlement date of the
    outstanding investment(s).This amount is charged from investors to allow
    them to receive in full the future coupon payments.
  </div>
);

export const totalIncomeTooltip = (
  <div style={{ fontSize: 12 }}>
    Formula: Interest received (+) Interest forecast (-) Fee expenses (-)
    Accrued interest expense.
  </div>
);

export const showLifetimeIncomeTooltip = (
  <div style={{ fontSize: 12 }}>
    By checking this box, investors can get the results not only covering
    outstanding investment(s) but also including Repaid investment(s).
  </div>
);
