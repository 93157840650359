import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { stepperRedirection } from "../../Utils/Reusables";

const getUser = Cookies.get("user");
const stringyUser = getUser === undefined ? null : JSON.parse(getUser);



const userInitialState = {
  country: {},
  current: stepperRedirection(stringyUser),
};

const common = createSlice({
  name: "commonLists",
  initialState: userInitialState,
  reducers: {
    countryList: (state, action) => {
      return { ...state, country: { ...action.payload } };
    },
    currentState: (state, action) => {
      return { ...state, current: action.payload };
    },
  },
});

export const { countryList, currentState } = common.actions;

export default common.reducer;
