import React from "react";
import AccountStatementCommonFilter from "./AccountStatementCommonFilter";

const AccountStatementFilterComponent = () => {
  return (
    <div>
    <AccountStatementCommonFilter/>
    </div>
  );
};

export default AccountStatementFilterComponent;
