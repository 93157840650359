import React, { useEffect } from "react";
import Identify_tickmark from "../../Assets/Images/identify_tickmark.svg";
import { notification } from "antd";
import WarningIcon from "../../Assets/Images/Warning.svg";

const VeriffPage = () => {
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    const NotificationPopup = () =>
      api.open({
        message: (
          <div className="sb-flex align-start">
            <img src={WarningIcon} alt="warn-icon" className="mr-5" />
            <p className="fw-600 m-0">
              We recomend to have the following ready
            </p>
          </div>
        ),
        description: (
          <div>
            <ul className="documents-ul">
              <li>
                <p className="color-blank">
                  Legal identity document i.e ID or Passport
                </p>
              </li>
              <li>
                <p className="color-blank">A camera set up</p>
              </li>
            </ul>
          </div>
        ),
        placement: "bottomRight",
        duration: 5,
        style: { width: 430 },
      });
    NotificationPopup();
  }, [api]);
  return (
    <div>
      {contextHolder}
      <p className="sb-verification-title">Verify Your Identity</p>

      <p className="mt-0 head-userId w-50 ">
        To complete the KYC/AML screening, please upload a copy of your
        identification document and perform a liveness test.
      </p>

      <div className="identify-proof-mainDiv mt-40">
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Good Lighting: Use proper lighting for better OCR results. Avoid
            overly dark or bright images.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Reflection: Minimize glares and reflections by not using flash when
            capturing images.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Focus: Capture clear, sharp images for improved OCR accuracy.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Angle: Keep document tilt within 10 degrees horizontally or
            vertically.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Margins (small): Document should fill 70-80% of the image.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Margins (big): Avoid excess empty space around the document.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Contrast: Ensure clear contrast between document and background.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Resolution: Use a camera with a resolution of at least Full HD
            (1920×1080) and autofocus.
          </p>
        </div>
        <div className="sb-flex-align-center">
          <p className="notes-tag">
            <img src={Identify_tickmark} alt="identify_tickmark" />
            Extraneous Objects: Keep hands or objects away from document data.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VeriffPage;
