import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row, message } from "antd";
import Add_Bank from "../../../Assets/Images/Icons/Dashboard/add_bank_account.svg";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InputDefault from "../../../Components/InputDefault/InputDefault";
import { GetBankAccountApi, addBankApi } from "../../../Apis/WalletApi";
import {
  setAccountDetails,
  setBankInfoModal,
} from "../../../Redux/Action/Wallet";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "../../../Assets/Images/SVGs/Info.svg";
import {
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
} from "../../../Utils/Reusables";

const AddBank = () => {
  const dispatch = useDispatch();
  const [, contextHolder] = message.useMessage();

  const [addBankModal, setAddBankModal] = useState(false);
  const [addBankLoader, setAddBankLoader] = useState(false);
  const [error, setError] = useState("");
  const [addBankData, setAddBankData] = useState({
    bankName: "",
    accountNumber: "",
    swiftCode: "",
    accountHolderName: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    bankName: false,
    accountNumber: false,
    swiftCode: false,
    accountHolderName: false,
  });

  const openModal = useSelector((state) => state.wallet.bankInfoModal);

  useEffect(() => {
    if (openModal === true) {
      setAddBankModal(true);
    } else {
      setAddBankModal(false);
    }
  }, [openModal]);

  const handleAddBankOpenModal = () => {
    setAddBankModal(true);
  };

  const HandleAddBank = () => {
    setAddBankLoader(true);
    setValidationErrors({
      bankName: true,
      accountNumber: true,
      swiftCode: true,
      accountHolderName: true,
    });

    if (addBankData?.accountNumber?.length < 5) {
      showMessageWithCloseIconError(
        "Account number should be atleast 5 characters long."
      );
      setValidationErrors({
        accountNumber: true,
      });
      setAddBankLoader(false);
    } else {
      const data = {
        bankName: addBankData?.bankName,
        accountNumber: addBankData?.accountNumber,
        accountHolderName: addBankData?.accountHolderName,
        branch: "",
        swiftCode: addBankData?.swiftCode,
      };
      addBankApi(data).then(async (response) => {
        if (Object.keys(response)?.length > 0) {
          setAddBankLoader(false);
        } else {
          showMessageWithCloseIcon(
            "Your banking information has been submitted successfully."
          );
          setAddBankData({
            bankName: "",
            accountNumber: "",
            swiftCode: "",
            accountHolderName: "",
          });
          setValidationErrors({
            bankName: false,
            accountNumber: false,
            swiftCode: false,
            accountHolderName: false,
          });
          setAddBankModal(false);
          getBankAccountNo();
          setAddBankLoader(false);
        }
      });
    }
  };

  const getBankAccountNo = async () => {
    try {
      const response = await GetBankAccountApi();
      if (response) {
        await setAccountDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleAccNumberChange = (e) => {
    let value = e.target.value;
    if (value.length > 20) {
      return;
    }
    setAddBankData({
      ...addBankData,
      accountNumber: value,
    });
  };

  const closeAddBankModel = async () => {
    await setBankInfoModal(false, dispatch);
    setAddBankModal(false);
  };

  const handleAccNumberBlur = () => {
    if (addBankData?.accountNumber.length < 5) {
      setError("Account number must be at least 5 characters long.");
    } else {
      setError("");
    }
  };

  return (
    <Col className="gutter-row" xl={11} lg={16} md={24} sm={24} xs={24}>
      <Row>
        {contextHolder}
        <Col
          sm={24}
          md={24}
          lg={24}
          className="gutter-row wallet-info-div"
          style={{ flex: 1 }}
        >
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} sm={12} md={12}>
              <p className="m-0 wallet-sub-head">Bank details</p>
            </Col>
            <Col xs={12} sm={12} md={12} className="sb-text-align-end">
              <div className="cursor-pointer" onClick={handleAddBankOpenModal}>
                <img src={Add_Bank} alt="add_bank_account" />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        className="wallet-addbank-modal"
        centered
        open={addBankModal}
        onCancel={closeAddBankModel}
        footer={null}
        maskClosable={false}
        width={616}
      >
        <p className="mt-0 wallet-sub-head mb-40">Add your bank details</p>
        <Form
          onFinish={HandleAddBank}
          name="wrap"
          labelCol={{ flex: "110px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
        >
          <Row gutter={16}>
            <Col className="gutter-row mb-20" md={24} sm={24} xs={24}>
              <label className="mb-4">Bank name</label>
              <InputDefault
                value={addBankData?.bankName}
                type="text"
                name="bankName"
                placeholder="Bank name"
                validationState={setValidationErrors}
                focusing={validationErrors?.bankName}
                onChange={({ target }) =>
                  setAddBankData({ ...addBankData, bankName: target.value })
                }
                required={true}
                errorMsg={"Bank name is Required"}
              />
            </Col>
            <Col className="gutter-row mb-20" md={12} sm={12} xs={24}>
              <label className="mb-4">Account number</label>
              <Form.Item
                validateStatus={error !== "" ? "error" : ""}
                help={
                  error && (
                    <p
                      style={{
                        color: "#e74c3c",
                        marginTop: "3px",
                        fontSize: "12px",
                        textAlign: "start",
                      }}
                      className="mb-0"
                    >
                      {error}
                    </p>
                  )
                }
              >
                <InputDefault
                  value={addBankData?.accountNumber}
                  type="number"
                  name="accountNumber"
                  placeholder="Account number"
                  validationState={setValidationErrors}
                  focusing={validationErrors?.accountNumber}
                  onChange={handleAccNumberChange}
                  required={true}
                  errorMsg={error || "Account number is required"}
                  onBlur={handleAccNumberBlur}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row mb-20" md={12} sm={12} xs={24}>
              <label className="mb-4">SWIFT Code</label>
              <InputDefault
                value={addBankData?.swiftCode}
                type="text"
                name="swiftCode"
                placeholder="SWIFT Code"
                validationState={setValidationErrors}
                focusing={validationErrors?.swiftCode}
                onChange={({ target }) =>
                  setAddBankData({
                    ...addBankData,
                    swiftCode: target.value,
                  })
                }
                required={true}
                errorMsg={"SWIFT code is required"}
              />
            </Col>
            <Col className="gutter-row mb-20" md={24} sm={24} xs={24}>
              <label className="mb-4">Account holder name</label>
              <InputDefault
                value={addBankData?.accountHolderName}
                placeholder="John Doe"
                type="text"
                name="accountHolderName"
                validationState={setValidationErrors}
                focusing={validationErrors?.accountHolderName}
                onChange={({ target }) =>
                  setAddBankData({
                    ...addBankData,
                    accountHolderName: target.value,
                  })
                }
                required={true}
                errorMsg={"Account holder name is required"}
              />
            </Col>
            <Col>
              <ButtonDefault title="Submit" loading={addBankLoader} />
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              gap: "4px",
              marginTop: "8px",
            }}
          >
            <img src={InfoIcon} alt="info-icon" />
            <label>Ensure accurate banking details before submitting.</label>
          </div>
        </Form>
      </Modal>
    </Col>
  );
};

export default AddBank;
