import ROUTES from "../Config/Routes";

import Cookies from "js-cookie";

import { REGTANK_ERROR_MESSAGES } from "./Constant";
import { checkStepStatus, redirectToVue } from "./Helpers";
import { notification } from "antd";

export function validatePassword(password) {
  var re = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/;
  return re.test(password);
}

export function camelCaseSting(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
}

export function getCountryNameByCode(countryList, code) {
  const country = countryList?.find((country) => country?.key === code);
  return country ? country?.value : null;
}

export function handleFinish(user, navigate) {
  if (user?.investorStatus === "ACTIVE") {
    redirectToVue();
  } else {
    if (
      checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") === false
    ) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      navigate(ROUTES.VERIFICATION);
    }
  }
}

export const getTransformedCountries = (countries) => {
  const transformed = countries?.map((country) => ({
    key: country.code,
    value: country.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All countries" });

  return transformed;
};

export const getTransformedIndustries = (industries) => {
  const transformed = industries?.map((industry) => ({
    key: industry.code,
    value: industry.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All industries" });

  return transformed;
};

export const getTransformedProductTypes = (products) => {
  const transformed = products?.map((product) => ({
    key: product.code,
    value: product.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All product type" });

  return transformed;
};

export const getTransformedLoanOriginator = (originators) => {
  const transformed = originators?.map((originator) => ({
    key: originator.code,
    value: originator.name,
  }));
  transformed?.unshift({ key: "ALL", value: "All originators" });

  return transformed;
};

export const generateErrorMessages = (codes) => {
  return codes.map((code) => REGTANK_ERROR_MESSAGES[code]);
};

export const formatDateWithTime = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

export const britishFormatDateWithTime = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const isPM = hours >= 12;
  hours = hours % 12 || 12;
  const formattedHours = String(hours).padStart(2, "0");

  const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes} ${isPM ? "PM" : "AM"
    }`;

  return formattedDate;
};

export const SupportChatButton = () => {
  const getUser = Cookies.get("user");
  const user = getUser && JSON.parse(getUser);
  return (
    <div
      data-tf-popover="rSEPG1V2"
      data-tf-opacity="100"
      data-tf-iframe-props="title=Kilde Customer Support Chat"
      data-tf-transitive-search-params
      data-tf-button-color="#0445AF"
      data-tf-medium="snippet"
      data-tf-hidden={`email=${user?.email},investor_number=${user?.number}`}
      style={{ all: "unset" }}
    />
  );
};

export const stepperRedirection = (user) => {
  if (
    user?.vwoFeatures?.identityVerificationSystem?.idvSystemToUse ===
    "veriff" &&
    checkStepStatus(user?.waitingVerificationSteps, "IDENTITY_VERIFICATION") ===
    false
  ) {
    return 3;
  } else {
    return 1;
  }
};

export const formatCamelCaseToTitle = (str) => {
  const words = str?.replace(/([A-Z])/g, " $1").trim();
  return words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatCurrency = (currencySymbol, value) => {
  const formatter = new Intl.NumberFormat("en-SG", {
    style: "currency",
    currency: "SGD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  let formattedValue = formatter.format(value).replace("$", "").trim();
  if (value < 0) {
    formattedValue = `-${currencySymbol}${formattedValue.slice(1)}`;
  } else {
    formattedValue = `${currencySymbol}${formattedValue}`;
  }

  if (formattedValue.includes(".") && !formattedValue.includes("..")) {
    const decimalIndex = formattedValue.indexOf(".");
    const decimalPart = formattedValue.slice(decimalIndex + 1);
    if (decimalPart.length === 1) {
      formattedValue += "0";
    }
  }

  return formattedValue;
};

export const getFilenameDetails = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  const fileFormat =
    lastDotIndex !== -1 ? filename.substring(lastDotIndex) : "";

  const baseName =
    lastDotIndex !== -1 ? filename.substring(0, lastDotIndex) : filename;

  if (baseName.length > 20) {
    return `${baseName.substring(0, 20)}...${fileFormat}`;
  } else {
    return filename;
  }
};

export const resetJivoChat = () => {
  let clearHistory = () => {
    if (window.jivo_api) {
      window.jivo_api.clearHistory();
      console.log("Client data is deleted");
    } else {
      console.log("jivo_api is not available");
    }
  };

  clearHistory();
};

export const showMessageWithCloseIcon = (text) => {
  notification.success({
    description: text,
    showProgress: true,
  });
};

export const showMessageWithCloseIconError = (text) => {
  notification.error({
    description: text,
    showProgress: true,
    onClose: () => {
      console.log("Notification closed");
    },
  });
};

export const formatString = (input) => {
  return input
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function isNonDBSSingaporeBank(swiftCode) {
  const bankCode = swiftCode?.substring(0, 4);
  const countryCode = swiftCode?.substring(4, 6);
  if (countryCode === "SG" && bankCode !== "DBSS") {
    return true;
  }
  return false;
}
