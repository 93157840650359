import React from "react";

const delete_stratergy = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 2H0.5C0.367392 2 0.240215 2.05268 0.146447 2.14645C0.0526785 2.24021 0 2.36739 0 2.5C0 2.63261 0.0526785 2.75979 0.146447 2.85355C0.240215 2.94732 0.367392 3 0.5 3H1V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H10C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12V3H11.5C11.6326 3 11.7598 2.94732 11.8536 2.85355C11.9473 2.75979 12 2.63261 12 2.5C12 2.36739 11.9473 2.24021 11.8536 2.14645C11.7598 2.05268 11.6326 2 11.5 2ZM10 12H2V3H10V12ZM3 0.5C3 0.367392 3.05268 0.240215 3.14645 0.146447C3.24021 0.0526784 3.36739 0 3.5 0H8.5C8.63261 0 8.75979 0.0526784 8.85355 0.146447C8.94732 0.240215 9 0.367392 9 0.5C9 0.632608 8.94732 0.759785 8.85355 0.853553C8.75979 0.947322 8.63261 1 8.5 1H3.5C3.36739 1 3.24021 0.947322 3.14645 0.853553C3.05268 0.759785 3 0.632608 3 0.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default delete_stratergy;
