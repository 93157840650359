/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Upload, notification, Row, Col, Button, Radio, Alert } from "antd";
import {
  EditOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
import {
  setIdentifyProofDetails,
  setIdentityVerificationResponse,
} from "../../../Redux/Action/KycIndividual";
import { showMessageWithCloseIconError } from "../../../Utils/Reusables";
import Identify_tickmark from "../../../Assets/Images/identify_tickmark.svg";
import { checkStepStatus } from "../../../Utils/Helpers";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const IdentifyProof = ({ type }) => {
  const dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const user = useSelector((state) => state.user);
  const idvInfo = useSelector(
    (state) => state?.kycIndividual?.livenessCheck?.documentInfo
  );

  const [docError, setDocError] = useState();
  const [state, setState] = useState(idvInfo?.documentType || "Identity");
  const [documentObject, setDocumentObject] = useState({
    frontImage: "",
    backImage: "",
  });

  useEffect(() => {
    if (state === "Passport") {
      setDocumentObject({
        frontImage: "",
      });
    } else {
      setDocumentObject({
        frontImage: "",
        backImage: "",
      });
    }
  }, [state]);

  const [documentUrls, setDocumentUrls] = useState({
    frontImage: idvInfo?.frontDocumentUrl || "",
    backImage: idvInfo?.backDocumentUrl || "",
  });

  const identifyVerification = useSelector(
    (state) => state.kycIndividual.identifyVerification
  );
  const handleChange = (info, documentType) => {
    const newDocumentObject = { ...documentObject };

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setDocumentUrls((prevUrls) => ({
          ...prevUrls,
          [documentType]: url,
        }));
      });
      newDocumentObject[documentType] = {
        ...newDocumentObject[documentType],
        loading: false,
        file: info.file.originFileObj,
      };
    } else if (info.file.status === "uploading") {
      newDocumentObject[documentType] = {
        ...newDocumentObject[documentType],
        loading: true,
        file: null,
      };
    }
    setDocumentObject(newDocumentObject);
  };

  useEffect(() => {
    if (identifyVerification === "") {
      setState("Identity");
      setDocumentObject({
        frontImage: "",
        backImage: "",
      });
      setDocumentUrls({
        frontImage: "",
        backImage: "",
      });
    }
  }, [identifyVerification]);

  useEffect(() => {
    setIdentityVerificationResponse("", dispatch);
    const dataToDispatch = {
      docs: documentObject,
      documentType: state,
    };
    setIdentifyProofDetails(dataToDispatch, dispatch);
  }, [dispatch, documentObject, state]);

  const handleEditDocumentField = (documentType) => {
    setDocError(undefined);
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".bmp,.jpg,.png";
    input.onchange = (event) =>
      handleEditFile(documentType, event.target.files[0]);
    input.click();
  };

  const handleCustomRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess();
    }, 1000);
  };

  const handleEditFile = (documentType, file) => {
    if (file) {
      getBase64(file, (url) => {
        setDocumentObject((prevDocumentObject) => ({
          ...prevDocumentObject,
          [documentType]: {
            ...prevDocumentObject[documentType],
            loading: true,
            file: file,
          },
        }));
        setDocumentUrls((prevUrls) => ({
          ...prevUrls,
          [documentType]: url,
        }));
      });
    }
  };

  const beforeUpload = (file) => {
    setDocError(undefined);
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = window.URL.createObjectURL(file);

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        if (width >= 500 && height >= 500 && width <= 8192 && height <= 8192) {
          const isJpgOrPng =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/bmp";
          if (!isJpgOrPng) {
            setDocError("You can only upload JPG/PNG/BMP file!");
            showMessageWithCloseIconError(
              "You can only upload JPG/PNG/BMP file!"
            );
          }
          resolve();
        } else {
          setDocError(
            "Image resolution must be between 500x500 and 8192x8192 pixels."
          );
          showMessageWithCloseIconError(
            "Image resolution must be between 500x500 and 8192x8192 pixels."
          );
          reject();
        }
      };

      image.onerror = () => {
        setDocError("Error loading the image.");
        showMessageWithCloseIconError("Error loading the image.");
        reject();
      };
    });
  };
  const uploadButton = (documentType) => (
    <div className="sb-document-upload">
      <span className="sb-fileUpload-icon">
        {documentObject[documentType].loading ? (
          <LoadingOutlined />
        ) : (
          <UploadOutlined />
        )}
      </span>
      <div>
        <span className="sb-file-span">
          Click to browse or drop here JPG, PNG, BMP
        </span>
      </div>
    </div>
  );

  const onChange = (e) => {
    setState(e.target.value);
  };

  const handleDivClick = (value) => {
    setState(value);
  };

  return (
    <div>
      {contextHolder}
      {type === "EMAIL_SENT" ? (
        <p
          className="sb-form-header"
          style={{ fontSize: 18, fontWeight: "bold", color: "tomato" }}
        >
          Please re-upload your identity document.
        </p>
      ) : (
        <p className="sb-verification-title">Verify Your Identity</p>
      )}
      <p className="sb-onboarding-subtitle m-0">
        To complete the KYC/AML screening, please upload a copy of your
        identification document and perform a liveness test.
      </p>
      <div className="identify-proof-mainDiv mt-40">
        <div>
          <p className="m-0 verification-header">Document Type:</p>
        </div>
        <Radio.Group
          onChange={onChange}
          value={state}
          checked={state}
          className="radio-kiled-button mt-10 w-100"
        >
          <Row className="sb-usertype sb-sub-usertype">
            <div className="sub-inv-type">
              <div
                className={`sb-justify-center-item-center cursor-pointer ${
                  state === "Identity" ? "radio-active-border" : "radio-border"
                } `}
                onClick={() => handleDivClick("Identity")}
              >
                <p className="m-0" style={{ fontSize: 15 }}>
                  Identity Card
                </p>
                <Radio value={"Identity"} />
              </div>
            </div>
            <div className="sub-inv-type">
              <div
                className={`sb-justify-center-item-center cursor-pointer ${
                  state === "Passport" ? "radio-active-border" : "radio-border"
                } `}
                onClick={() => handleDivClick("Passport")}
              >
                <p className="m-0" style={{ fontSize: 15 }}>
                  Passport
                </p>
                <Radio value={"Passport"}></Radio>
              </div>
            </div>
          </Row>
        </Radio.Group>
        <div className="sb-identify-fileupload identify-notes-margin">
          <Row gutter={16}>
            {Object.keys(documentObject).map((documentType, key) => (
              <Col xs={24} sm={24} md={12} lg={12} xl={12} key={key}>
                <label className="verification-header">
                  {documentType === "frontImage" ? "Front image" : "Back image"}
                  {(documentUrls[documentType] ||
                    (idvInfo !== undefined &&
                      checkStepStatus(
                        user?.waitingVerificationSteps,
                        "IDENTITY_VERIFICATION"
                      ) === true)) && (
                    <Button
                      className="edit-identifybtn"
                      type="text"
                      icon={<EditOutlined />}
                      onClick={() => handleEditDocumentField(documentType)}
                    />
                  )}
                </label>
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={(info) => handleChange(info, documentType)}
                  customRequest={handleCustomRequest}
                >
                  {documentUrls[documentType] ? (
                    <img
                      src={documentUrls[documentType]}
                      alt={documentType}
                      style={{
                        width: "100%",
                        height: "184px",
                        objectFit: "contain",
                        borderRadius: "8px",
                        border: "1px solid #d9d9d9",
                      }}
                    />
                  ) : (
                    uploadButton(documentType)
                  )}
                </Upload>
              </Col>
            ))}
          </Row>
        </div>
        {docError && (
          <div>
            <Alert message={docError} type="error" showIcon />
          </div>
        )}
        <div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Good Lighting: Use proper lighting for better OCR results. Avoid
              overly dark or bright images.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Reflection: Minimize glares and reflections by not using flash
              when capturing images.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Focus: Capture clear, sharp images for improved OCR accuracy.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Angle: Keep document tilt within 10 degrees horizontally or
              vertically.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Margins (small): Document should fill 70-80% of the image.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Margins (big): Avoid excess empty space around the document.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Contrast: Ensure clear contrast between document and background.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Resolution: Use a camera with a resolution of at least Full HD
              (1920×1080) and autofocus.
            </p>
          </div>
          <div className="sb-flex-align-center">
            <p className="notes-tag">
              <img src={Identify_tickmark} alt="identify_tickmark" />
              Extraneous Objects: Keep hands or objects away from document data.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentifyProof;
