import React from "react";

import Download_icon from "../../../Assets/Images/download_icon.svg";
import View_icon from "../../../Assets/Images/view_icon.svg";

import Borrower_pdf from "../../../Assets/Images/borrower_pdf.svg";
import { Col, Row, Tooltip } from "antd";
import GlobalVariabels from "../../../Utils/GlobalVariabels";
import axios from "axios";
import { getFilenameDetails } from "../../../Utils/Reusables";

const Document = ({ TrancheRes }) => {
  const handleView = (file) => {
    window.open(`${GlobalVariabels.VIEW_IMG}/${file}`, "_blank");
  };

  const handleDownload = (fileReference) => {
    if (fileReference) {
      const fileUrl = `${GlobalVariabels.VIEW_IMG}/${fileReference}`;
      axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", fileReference);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };

  return (
    <Col xs={24} sm={24} md={12} lg={24} className="trach-info medium-doc-col">
      <p className="mt-0 tranch-sub-head">Documents</p>
      <Row>
        {TrancheRes?.attachments?.length > 0
          ? TrancheRes?.attachments?.map((file, index) => (
              <React.Fragment key={index}>
                <Col xs={12} lg={12}>
                  <p className="mb-4 mt-0 borrower-info-tag">
                    <div className="borrower-pdf-div">
                      <div>
                        <img src={Borrower_pdf} alt="pdf_icon" />
                      </div>
                      <div>
                        <Tooltip title={file.fileName}>
                          <p className="m-0">
                            {getFilenameDetails(file.fileName)}
                          </p>
                        </Tooltip>
                      </div>
                    </div>
                  </p>
                </Col>
                <Col xs={12} lg={12} className="sb-justify-center-item-center">
                  <div className="borrower-pdf-div">
                    <div
                      onClick={() => handleView(file?.fileReference)}
                      className="cursor-pointer"
                    >
                      <img src={View_icon} alt="view_icon" />
                    </div>
                    <div
                      onClick={() => handleDownload(file?.fileReference)}
                      className="cursor-pointer"
                    >
                      <img src={Download_icon} alt="download_icon" />
                    </div>
                  </div>
                </Col>
              </React.Fragment>
            ))
          : "No Document Found"}
      </Row>
    </Col>
  );
};

export default Document;
