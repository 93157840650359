import React from "react";
import CommonFilter from "./CommonFilter";
import { Button, Drawer } from "antd";
import MenuCloseIcon from "../../../Assets/Images/menu-close.svg";
const ShowFilterModal = ({ filterModal, setFilterModal }) => {
  return (
    <>
      <Drawer
        title="Show Filters"
        footer={null}
        placement="right"
        closable={false}
        onClose={() => setFilterModal(false)}
        open={filterModal}
        className="drawer-header"
        size="large"
        extra={
          <Button
            className="user-normal-btn p-0"
            onClick={() => setFilterModal(false)}
          >
            <img
              src={MenuCloseIcon}
              alt="user_icon"
              className="drawer-close-button"
            />
          </Button>
        }
      >
        <div className="filter-modal-div">
          <CommonFilter />
        </div>
      </Drawer>
    </>
  );
};

export default ShowFilterModal;
