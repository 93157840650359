import React from "react";
import { Radio } from "antd";
import "./style.css";

const RadioDefault = ({ label, checked, value, className, onChange }) => {
  const handleDivClick = () => {
    if (!checked) {
      onChange({ target: { value } });
    }
  };

  return (
    <div
      className={`radio-container ${className}`}
      style={{ borderColor: checked ? "#22B5E9" : "#1A202C1A" }}
      onClick={handleDivClick}
    >
      <p style={{ color: checked ? "#22B5E9" : "#000" }}>{label}</p>
      <Radio value={value} checked={checked} />
    </div>
  );
};

export default RadioDefault;
