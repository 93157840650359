/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import { Col, Pagination, Row, Skeleton, Table, Tag, Button } from "antd";
import FilterComponent from "./FilterComponent";
import ManualInvestmentCard from "./ManualInvestmentCard";
import SlidersHorizontal from "../../../Assets/Images/SlidersHorizontal.svg";
import Arrow from "../../../Assets/Images/arrow.svg";
import ShowFilterModal from "./ShowFilterModal";
import { PastTranchListing, TranchListing } from "../../../Apis/DashboardApi";
import ROUTES from "../../../Config/Routes";
import { useNavigate } from "react-router-dom";
import { britishFormatDate } from "../../../Utils/Helpers";
import "./style.css";
import File from "../../../Assets/Images/File.svg";
import { formatCurrency } from "../../../Utils/Reusables";
import { useDispatch, useSelector } from "react-redux";
import { setTranchFilter } from "../../../Redux/Action/Dashboard";

const ManualInvestment = ({ showButtonActive }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const filterData = useSelector((state) => state);
  const fromInterest = filterData?.dashboard?.tranchFilter?.data?.fromInterest;
  const toInterest = filterData?.dashboard?.tranchFilter?.data?.toInterest;
  const currency = filterData?.dashboard?.tranchFilter?.data?.currency;
  const industry = filterData?.dashboard?.tranchFilter?.data?.industry;
  const earlyRedemptionFrequency =
    filterData?.dashboard?.tranchFilter?.data?.EarlyRedemptionFrequency;
  const country = filterData?.dashboard?.tranchFilter?.data?.country;
  const dealStatus = filterData?.dashboard?.tranchFilter?.data?.dealStatus;

  const [filterModal, setFilterModal] = useState(false);
  const [tranchList, setTranchList] = useState();
  const [pastTranchList, setPastTranchList] = useState();
  const [totalItem, setTotalItem] = useState();
  const [pastTotalItem, setPastTotalItem] = useState();
  const [itemsPerPage, setItemPerPage] = useState(6);
  const [pastItemPerPage, setPastItemPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [pastCurrentPage, setPastCurrentPage] = useState(1);
  const [tranchLoader, setTranchLoader] = useState(false);
  const [pastDealLoader, setPastDealLoader] = useState(false);
  const [trancheCountry, setTrancheCountry] = useState();

  useEffect(() => {
    setTranchFilter(
      JSON.parse(localStorage.getItem("accStatementFilterData")),
      dispatch
    );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("tranchePastManualPageSize") !== null) {
      setPastItemPerPage(
        parseInt(localStorage.getItem("tranchePastManualPageSize"))
      );
    }
  }, [pastItemPerPage]);

  useEffect(() => {
    if (localStorage.getItem("trancheManualPageSize") !== null) {
      setItemPerPage(parseInt(localStorage.getItem("trancheManualPageSize")));
    }
  }, [itemsPerPage]);

  const handleTranchListing = (page) => {
    setTranchLoader(true);
    const filterPayload = {
      page: page,
      pageSize: itemsPerPage,
      ordering: [
        {
          field: "created_at",
          asc: false,
        },
      ],
    };

    if (fromInterest) {
      filterPayload.interestRateFrom = fromInterest;
    }
    if (toInterest) {
      filterPayload.interestRateTo = toInterest;
    }
    if (currency) {
      filterPayload.currencyCode = currency;
    }
    if (industry) {
      filterPayload.industry = industry;
    }
    if (earlyRedemptionFrequency) {
      filterPayload.earlyRedemptionFrequency = earlyRedemptionFrequency;
    }
    if (country) {
      filterPayload.country = country;
    }

    if (dealStatus) {
      filterPayload.includeFundedTranches = false;
    }

    if (dealStatus === "all" || dealStatus === "active") {
      TranchListing(filterPayload).then((trachRes) => {
        setTranchLoader(false);
        setTotalItem(trachRes?.totalItems);
        setTranchList(trachRes?.items);
        setTrancheCountry(trachRes?.countries);
      });
    }
  };

  const handlePastDealListing = (page) => {
    setPastDealLoader(true);
    const filterPayload = {
      page: page,
      pageSize: pastItemPerPage,
      ordering: [
        {
          field: "created_at",
          asc: false,
        },
      ],
    };

    if (fromInterest) {
      filterPayload.interestRateFrom = fromInterest;
    }
    if (toInterest) {
      filterPayload.interestRateTo = toInterest;
    }
    if (currency) {
      filterPayload.currencyCode = currency;
    }
    if (industry) {
      filterPayload.industry = industry;
    }
    if (earlyRedemptionFrequency) {
      filterPayload.earlyRedemptionFrequency = earlyRedemptionFrequency;
    }
    if (country) {
      filterPayload.country = country;
    }

    if (dealStatus) {
      filterPayload.includeFundedTranches = true;
    }

    if (dealStatus === "all" || dealStatus === "funded") {
      PastTranchListing(filterPayload).then((trachRes) => {
        setPastDealLoader(false);
        setPastTotalItem(trachRes?.totalItems);
        setPastTotalItem(trachRes?.totalItems);
        setPastTranchList(trachRes?.items);
        setTrancheCountry(trachRes?.countries);
      });
    }
  };

  const resetPageAndFetch = debounce(() => {
    setCurrentPage(1);
    handleTranchListing(1);
  }, 300);

  const resetPastPageAndFetch = debounce(() => {
    setPastCurrentPage(1);
    handlePastDealListing(1);
  }, 300);

  useEffect(() => {
    handleTranchListing(currentPage);
  }, []);

  useEffect(() => {
    const resetAndFetchData = () => {
      if (
        fromInterest !== undefined ||
        toInterest !== undefined ||
        earlyRedemptionFrequency !== undefined ||
        country !== undefined ||
        currency !== undefined ||
        industry !== undefined ||
        dealStatus !== undefined
      ) {
        resetPageAndFetch();
      }
    };

    if (itemsPerPage !== undefined) {
      resetPageAndFetch();
    }

    if (
      fromInterest !== undefined ||
      toInterest !== undefined ||
      earlyRedemptionFrequency !== undefined ||
      country !== undefined ||
      currency !== undefined ||
      industry !== undefined ||
      dealStatus !== undefined
    ) {
      resetAndFetchData();
    }

    return () => {
      resetPageAndFetch.cancel();
    };
  }, [
    fromInterest,
    toInterest,
    earlyRedemptionFrequency,
    country,
    currency,
    industry,
    dealStatus,
    itemsPerPage,
  ]);

  useEffect(() => {
    const resetAndFetchPastData = () => {
      if (
        fromInterest !== undefined ||
        toInterest !== undefined ||
        earlyRedemptionFrequency !== undefined ||
        country !== undefined ||
        currency !== undefined ||
        industry !== undefined ||
        dealStatus !== undefined
      ) {
        resetPastPageAndFetch();
      }
    };

    if (pastItemPerPage !== undefined) {
      resetPastPageAndFetch();
    }

    if (
      fromInterest !== undefined ||
      toInterest !== undefined ||
      earlyRedemptionFrequency !== undefined ||
      country !== undefined ||
      currency !== undefined ||
      industry !== undefined ||
      dealStatus !== undefined
    ) {
      resetAndFetchPastData();
    }

    return () => {
      resetPastPageAndFetch.cancel();
    };
  }, [
    fromInterest,
    toInterest,
    earlyRedemptionFrequency,
    country,
    currency,
    industry,
    dealStatus,
    pastItemPerPage,
  ]);

  const handleChange = (page) => {
    setCurrentPage(page);
    handleTranchListing(page);
  };

  const handlePastChange = (page) => {
    setPastCurrentPage(page);
    handlePastDealListing(page);
  };

  const onShowSizeChange = (current, pageSize) => {
    setItemPerPage(pageSize);
    localStorage.setItem("trancheManualPageSize", pageSize);
  };

  const onShowPastSizeChange = (current, pageSize) => {
    setPastItemPerPage(pageSize);
    localStorage.setItem("tranchePastManualPageSize", pageSize);
  };

  const columns = [
    {
      title: "Tranche number / Borrower",
      dataIndex: "TrancheNumber",
    },
    {
      title: "",
      dataIndex: "Blank",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Minimum investment",
      dataIndex: "MinimumInvestment",
    },
    {
      title: "Industry",
      dataIndex: "Industry",
    },
    {
      title: "Interest Rate",
      dataIndex: "InterestRate",
    },
    {
      title: "Available to invest",
      dataIndex: "AvailableInvest",
    },
    {
      title: "Maturity Date",
      dataIndex: "MaturityDate",
    },
    {
      title: "Bond Sold",
      dataIndex: "BondSold",
    },
    {
      title: "",
      dataIndex: "RightArrow",
    },
  ];
  const fundedColumns = [
    {
      title: "Tranche number / Borrower",
      dataIndex: "TrancheNumber",
    },
    {
      title: "",
      dataIndex: "Blank",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "Minimum investment",
      dataIndex: "MinimumInvestment",
    },
    {
      title: "Industry",
      dataIndex: "Industry",
    },
    {
      title: "Interest Rate",
      dataIndex: "InterestRate",
    },
    {
      title: "Available to invest",
      dataIndex: "AvailableInvest",
    },
    {
      title: "Maturity Date",
      dataIndex: "MaturityDate",
    },
    {
      title: "Bond Sold",
      dataIndex: "BondSold",
    },
    {
      title: "",
      dataIndex: "RightArrow",
    },
  ];

  const data = tranchList?.map((index) => ({
    key: { index },
    Country:
      index?.countries?.length > 0
        ? index?.countries?.map((countryCode, index) => (
            <span
              style={{
                filter: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))",
              }}
              key={index}
              className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
            />
          ))
        : "-",
    TrancheNumber: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
        className="cursor-pointer"
      >
        <p className="m-0">{index?.trancheNumber}</p>
        <h3 className="m-0">{index?.details?.title}</h3>
      </div>
    ),
    Blank: (
      <div>
        {index?.capitalCallFrequency === 0 ? (
          ""
        ) : (
          <Tag color="default" className="tranche-tag">
            Early redemption
          </Tag>
        )}
        <br />
        <Tag color="default" className="tranche-tag">
          {index?.collateral[0]}
        </Tag>
      </div>
    ),
    Currency: index?.currencyCode,
    MinimumInvestment: formatCurrency("$", index?.principalIssued),
    Industry: index?.trancheType
      ?.toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase()),
    InterestRate: formatCurrency("", index?.interestRate) + "%",
    AvailableInvest: formatCurrency("$", index?.principalAvailable),
    MaturityDate: britishFormatDate(index?.maturityDate),
    BondSold: formatCurrency("", index?.debenturesSold),
    RightArrow: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
      >
        <img src={Arrow} alt="right_arrow" className="cursor-pointer" />
      </div>
    ),
  }));

  const fundedData = pastTranchList?.map((index) => ({
    key: { index },
    Country:
      index?.countries?.length > 0
        ? index?.countries?.map((countryCode, index) => (
            <span
              style={{
                filter: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))",
              }}
              key={index}
              className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
            />
          ))
        : "-",
    TrancheNumber: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
        className="cursor-pointer"
      >
        <p className="m-0">{index?.trancheNumber}</p>
        <h3 className="m-0">{index?.details?.title}</h3>
      </div>
    ),
    Blank: (
      <div>
        {index?.capitalCallFrequency === 0 ? (
          ""
        ) : (
          <Tag color="default" className="tranche-tag">
            Early redemption
          </Tag>
        )}
        <br />
        <Tag color="default" className="tranche-tag">
          {index?.collateral[0]}
        </Tag>
      </div>
    ),
    Currency: index?.currencyCode,
    MinimumInvestment: formatCurrency("$", index?.principalIssued),
    Industry: index?.trancheType
      ?.toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (c) => c.toUpperCase()),
    InterestRate: formatCurrency("", index?.interestRate) + "%",
    AvailableInvest: formatCurrency("$", index?.principalAvailable),
    MaturityDate: britishFormatDate(index?.maturityDate),
    BondSold: formatCurrency("", index?.debenturesSold),
    RightArrow: (
      <div
        onClick={() => {
          navigate(`${ROUTES.TRANCH_INVEST}/${index?.uuid}`);
        }}
      >
        <img src={Arrow} alt="right_arrow" className="cursor-pointer" />
      </div>
    ),
  }));

  return (
    <Row gutter={window.innerWidth >= 576 ? 20 : 0}>
      <Col xs={5} md={4} lg={4} className="invest-filter">
        <FilterComponent trancheCountry={trancheCountry} />
        <ShowFilterModal
          filterModal={filterModal}
          setFilterModal={setFilterModal}
        />
      </Col>

      <Col xs={24} md={20} lg={20} className="gutter-row">
        {dealStatus === "all" || dealStatus === "active" ? (
          <>
            {showButtonActive === true ? (
              <p className="tranche-header mt-0 mb-24">Active deals</p>
            ) : (
              ""
            )}
          </>
        ) : null}

        <Col xs={24} className="invest-col">
          <Button
            className="invest-showfilterbutton"
            onClick={() => setFilterModal(true)}
            style={{ height: 36 }}
          >
            Show Filtres
            <img src={SlidersHorizontal} alt="img" />
          </Button>
        </Col>

        {showButtonActive === true ? (
          <>
            {dealStatus === "all" || dealStatus === "active" ? (
              <>
                <Row gutter={20}>
                  {tranchLoader === false ? (
                    tranchList?.length > 0 ? (
                      tranchList?.map((tranchInfo, index) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          key={index}
                          className="mb-20"
                        >
                          <ManualInvestmentCard tranchInfo={tranchInfo}/>
                        </Col>
                      ))
                    ) : (
                      <div className="not-found-container">
                        <img alt="nothing found" src={File} />
                        <p className="not-found-text">Nothing found</p>
                      </div>
                    )
                  ) : (
                    [0, 1, 2].map((index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        id={index}
                        className="mb-20"
                      >
                        <div className="tranch-list-skeleton">
                          <Skeleton.Button loading={true} active size="large" />
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
                {tranchList?.length > 0 && (
                  <Pagination
                    className="card-margin-top tranch-table-pagination"
                    defaultCurrent={1}
                    defaultPageSize={itemsPerPage}
                    current={currentPage}
                    total={totalItem}
                    onChange={handleChange}
                    showSizeChanger
                    onShowSizeChange={onShowSizeChange}
                    pageSizeOptions={["6", "20", "50", "100"]}
                    locale={{
                      items_per_page: " ",
                    }}
                  />
                )}
              </>
            ) : null}

            {dealStatus === "all" || dealStatus === "funded" ? (
              <>
                <p className="tranche-header mt-40 mb-20">Funded Deals</p>
                <Row gutter={20}>
                  {pastDealLoader === false ? (
                    pastTranchList?.length > 0 ? (
                      pastTranchList?.map((tranchInfo, index) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          key={index}
                          className="mb-20"
                        >
                          <ManualInvestmentCard tranchInfo={tranchInfo}/>
                        </Col>
                      ))
                    ) : (
                      <div className="not-found-container">
                        <img alt="nothing found" src={File} />
                        <p className="not-found-text">Nothing found</p>
                      </div>
                    )
                  ) : (
                    [0, 1, 2].map((index) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={8}
                        id={index}
                        className="mb-20"
                      >
                        <div className="tranch-list-skeleton">
                          <Skeleton.Button loading={true} active size="large" />
                        </div>
                      </Col>
                    ))
                  )}
                </Row>
                {pastTranchList?.length > 0 && (
                  <Pagination
                    className="card-margin-top card-margin-bottom tranch-table-pagination mb-16"
                    defaultCurrent={1}
                    defaultPageSize={pastItemPerPage}
                    current={pastCurrentPage}
                    total={pastTotalItem}
                    onChange={handlePastChange}
                    showSizeChanger
                    onShowSizeChange={onShowPastSizeChange}
                    pageSizeOptions={["6", "20", "50", "100"]}
                    locale={{
                      items_per_page: " ",
                    }}
                  />
                )}
              </>
            ) : null}
          </>
        ) : (
          <>
            {dealStatus === "all" || dealStatus === "active" ? (
              <Col xs={24} className="list-view-div">
                <h3 className="mt-0">Active deals</h3>
                <div className="table-container">
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="trache-table"
                    loading={tranchLoader}
                  />
                  {tranchList?.length > 0 && (
                    <Pagination
                      className="tranch-table-pagination mb-16"
                      defaultCurrent={1}
                      defaultPageSize={itemsPerPage}
                      current={currentPage}
                      total={totalItem}
                      onChange={handleChange}
                      showSizeChanger
                      onShowSizeChange={onShowSizeChange}
                      pageSizeOptions={["6", "20", "50", "100"]}
                      locale={{
                        items_per_page: " ",
                      }}
                    />
                  )}
                </div>
              </Col>
            ) : null}
            {dealStatus === "all" || dealStatus === "funded" ? (
              <Col xs={24} className="list-view-div mt-20">
                <h3 className="mt-0">Funded deals</h3>
                <div className="table-container">
                  <Table
                    columns={fundedColumns}
                    dataSource={fundedData}
                    pagination={false}
                    className="trache-table"
                    loading={pastDealLoader}
                  />
                  {pastTranchList?.length > 0 && (
                    <Pagination
                      className="tranch-table-pagination mb-16"
                      defaultCurrent={1}
                      defaultPageSize={pastItemPerPage}
                      current={pastCurrentPage}
                      total={pastTotalItem}
                      onChange={handlePastChange}
                      showSizeChanger
                      onShowSizeChange={onShowPastSizeChange}
                      pageSizeOptions={["6", "20", "50", "100"]}
                      locale={{
                        items_per_page: " ",
                      }}
                    />
                  )}
                </div>
              </Col>
            ) : null}
          </>
        )}
      </Col>
    </Row>
  );
};

export default ManualInvestment;
