import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../Config/Routes";
import { Checkbox, Modal, Radio, Row, notification } from "antd";
import Individual from "../../Assets/Images/individual.svg";
import Institutional from "../../Assets/Images/Institutional.svg";
import { ONBOARDING_INDIVIDUAL, QUESTIONNAIRE } from "../../Utils/Constant";
import { updateKycInvestor } from "../../Apis/InvestorApi";
import { setInvestorIdentificationDetails } from "../../Redux/Action/KycIndividual";
import Alert from "../../Assets/Images/alert.svg";
import WarningCircle from "../../Assets/Images/WarningCircle.svg";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault";
import { checkStepStatus, deleteAllCookies } from "../../Utils/Helpers";

const ComonVerification = ({
  value,
  setValue,
  selectedOption,
  setSelectedOption,
  questionnaire,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [modal2Open, setModal2Open] = useState(false);
  const [lastCheckboxChecked, setLastCheckboxChecked] = useState(false);

  let initialTasks = [];
  initialTasks = [
    {
      id: QUESTIONNAIRE.kycQuestionAnnualIncome,
      value: "kycQuestionAnnualIncome",
      label: "An annual income of SGD 300,000 or more.",
      completed: false,
    },
    {
      id: QUESTIONNAIRE.kycQuestionPersonalAssets,
      value: "kycQuestionPersonalAssets",
      label:
        "The value of your “Net Personal Assets” is SGD 2 million or more.",
      completed: false,
    },
    {
      id: QUESTIONNAIRE.kycQuestionFinancialAssets,
      value: "kycQuestionFinancialAssets",
      label:
        "The value of your “Net Financial Assets” is SGD 1 million or more.",
      completed: false,
    },
  ];

  useEffect(() => {
    const initialTasks =
      (selectedOption === "individual" || selectedOption === "INDIVIDUAL") &&
      value === "ACCREDITED"
        ? [
            {
              id: QUESTIONNAIRE.kycQuestionAnnualIncome,
              value: "kycQuestionAnnualIncome",
              label: "An annual income of SGD 300,000 or more.",

              completed: false,
            },
            {
              id: QUESTIONNAIRE.kycQuestionPersonalAssets,
              value: "kycQuestionPersonalAssets",
              label:
                "The value of your “Net Personal Assets” is SGD 2 million or more.",
              completed: false,
            },
            {
              id: QUESTIONNAIRE.kycQuestionFinancialAssets,
              value: "kycQuestionFinancialAssets",
              label:
                "The value of your “Net Financial Assets” is SGD 1 million or more.",
              completed: false,
            },
          ]
        : value === "EXPERT"
        ? [
            {
              id: QUESTIONNAIRE.companyDirectlyCoversHoldingCMP,
              value: "companyDirectlyCoversHoldingCMP",
              label:
                "Your business or the business of the company you work for involves the acquisition and disposal, or the holding, of capital markets products",
              completed: false,
            },
            {
              id: QUESTIONNAIRE.companyInvolvesDisposalHoldingCMP,
              value: "companyInvolvesDisposalHoldingCMP",
              label:
                "Your responsibility within the company directly covers the acquisition and disposal, or the holding, of capital markets products.",
              completed: false,
            },
          ]
        : value === "INSTITUTIONAL"
        ? [
            {
              id: QUESTIONNAIRE.kybQuestionCapitalMarketsServiceLicense,
              value: "kybQuestionCapitalMarketsServiceLicense",
              label:
                "I represent a corporation that is a holder of a Capital Markets Service License",
              completed: false,
            },
            {
              id: QUESTIONNAIRE.kybQuestionLicensedUnderTheFinanceCompaniesAct,
              value: "kybQuestionLicensedUnderTheFinanceCompaniesAct",
              label:
                "I represent a finance company that is licensed under the Finance Companies Act.",
              completed: false,
            },
            {
              id: QUESTIONNAIRE.kybQuestionSecuritiesandFuturesAct,
              value: "kybQuestionSecuritiesandFuturesAct",
              label:
                "I represent an institutional investor, as defined in Securities and Futures Act",
              completed: false,
            },
          ]
        : (selectedOption === "company" || selectedOption === "COMPANY") &&
          value === "ACCREDITED" && [
            {
              id: QUESTIONNAIRE.kybQuestionNetAssets,
              value: "kybQuestionNetAssets",
              label:
                "I represent a corporation with net assets exceeding S$ 10 million (or equivalent).",
              completed: false,
            },
            {
              id: QUESTIONNAIRE.kybQuestionEntireShareCapitalIsOwnedByOneOrMorePersons,
              value: "kybQuestionEntireShareCapitalIsOwnedByOneOrMorePersons",
              label:
                "I represent a corporation where the entire share capital is owned by one or more persons, all of whom are accredited investors.",
              completed: false,
            },
          ];
    setTasks(initialTasks);
    const getData = questionnaire?.questionnaire;
    let getDetails;

    if (
      questionnaire?.questionnaire !== undefined &&
      questionnaire?.questionnaire !== null
    ) {
      getDetails = Object.values(getData);
    }

    if (getDetails) {
      const updatedTasks = initialTasks?.map((task) => {
        const matchingTask = getDetails.find(
          (userTask) => userTask === task.id
        );

        return {
          ...task,
          completed: matchingTask ? true : false,
        };
      });
      setTasks(updatedTasks);
    }
  }, [
    questionnaire?.questionnaire,
    user?.waitingVerificationSteps,
    value,
    selectedOption,
  ]);

  const [tasks, setTasks] = useState(initialTasks);

  const handleCheckboxChange = async (taskId) => {
    if (taskId) {
      setLastCheckboxChecked(false);
    }
    const updatedTasks = tasks?.map((task) =>
      task.id === taskId ? { ...task, completed: !task.completed } : task
    );
    setTasks(updatedTasks);
    setLastCheckboxChecked(false);

    setInvestorIdentificationDetails({ data: updatedTasks }, dispatch);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleDivClick = (value) => {
    setValue(value);
  };

  const handleLastCheckboxChange = (value) => {
    setModal2Open(true);
    setLastCheckboxChecked(true);
    if (value) {
      setTasks(tasks?.map((task) => ({ ...task, completed: false })));
      const data = [
        {
          id: "task4",
          label: "I am not an accredited investor.",
          completed: true,
        },
      ];
      setInvestorIdentificationDetails({ data: data }, dispatch);
    }
  };

  const handleCloseModal = async () => {
    const requestBody = {
      identificationQuestions: ["I am not an accredited investor."],
    };
    const response = await updateKycInvestor(requestBody);
    if (
      response?.kyc?.KYCStatus === ONBOARDING_INDIVIDUAL.INVESTOR_IDENTIFICATION
    ) {
      deleteAllCookies();
      navigate(ROUTES.LOGIN);
    } else {
      notification.error({
        type: "error",
        message: "Something went Wrong!",
      });
    }
  };

  return (
    <div>
      <div
        className={
          user?.verificationState === "" || user?.verificationState === null
            ? null
            : "p-none"
        }
      >
        <div className="sb-verification-content-page">
          <div gutter={16} className="sb-usertype sb-main-usertype">
            <div
              className="gutter-row w-100"
              span={4}
              xs={12}
              sm={12}
              md={12}
              lg={10}
            >
              <div
                className={`p-relative bydefault-select ${
                  selectedOption === "individual" ||
                  selectedOption === "INDIVIDUAL"
                    ? "sb-individual-kilde-active"
                    : "sb-individual"
                }`}
                onClick={() => handleOptionChange("individual")}
              >
                <img
                  src={Individual}
                  alt="Individual"
                  className="wpx-100 hpx-100"
                  style={{ width: 35 }}
                />
                <p className="mt-8 mb-0">Individual</p>
                {selectedOption === "individual" ||
                selectedOption === "INDIVIDUAL" ? null : (
                  <div className="without-checked-div" />
                )}
                <input
                  type="radio"
                  name="radio-group"
                  checked={
                    selectedOption === "individual" ||
                    selectedOption === "INDIVIDUAL"
                  }
                  onChange={() => handleOptionChange("individual")}
                />
              </div>
            </div>
            <div
              className="gutter-row w-100"
              span={4}
              xs={12}
              sm={12}
              md={12}
              lg={10}
            >
              <div
                className={`p-relative bydefault-select ${
                  selectedOption === "company" || selectedOption === "COMPANY"
                    ? "sb-individual-kilde-active"
                    : "sb-corporate"
                }`}
                onClick={() => handleOptionChange("company")}
              >
                <img
                  src={Institutional}
                  alt="Institutional"
                  className="wpx-100 hpx-100"
                  style={{ width: 45 }}
                />
                <p className="mt-8 mb-0">Corporate</p>
                {selectedOption === "company" ||
                selectedOption === "COMPANY" ? null : (
                  <div className="without-checked-div" />
                )}
                <input
                  type="radio"
                  name="radio-group"
                  checked={
                    selectedOption === "company" || selectedOption === "COMPANY"
                  }
                  onChange={() => handleOptionChange("company")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          user?.verificationState === "" || user?.verificationState === null
            ? "verification-div"
            : "verification-div p-none"
        }
      >
        <div>
          <p className="m-0 verification-header">Select type:</p>
        </div>

        {selectedOption === "individual" || selectedOption === "INDIVIDUAL" ? (
          <div className="w-100">
            <Radio.Group
              onChange={onChange}
              value={value}
              checked={value}
              className="radio-kiled-button mt-10 w-100"
            >
              <Row className="sb-usertype sb-sub-usertype">
                <div className="sub-inv-type">
                  <div
                    style={{ justifyContent: "space-between" }}
                    className={`sb-justify-center-item-center cursor-pointer ${
                      value === "ACCREDITED"
                        ? "radio-active-border"
                        : "radio-border"
                    } `}
                    onClick={() => handleDivClick("ACCREDITED")}
                  >
                    <p className="m-0">Accredited Investor</p>
                    <Radio value={"ACCREDITED"}></Radio>
                  </div>
                </div>
                <div className="sub-inv-type">
                  <div
                    style={{ justifyContent: "space-between" }}
                    className={`sb-justify-center-item-center cursor-pointer ${
                      value === "EXPERT"
                        ? "radio-active-border"
                        : "radio-border"
                    } `}
                    onClick={() => handleDivClick("EXPERT")}
                  >
                    <p className="m-0">Expert Investor</p>
                    <Radio value={"EXPERT"}></Radio>
                  </div>
                </div>
              </Row>
            </Radio.Group>
          </div>
        ) : (
          <div className="w-100">
            <Radio.Group
              onChange={onChange}
              value={value}
              checked={value}
              className="radio-kiled-button mt-10 w-100"
            >
              <Row className="sb-usertype sb-sub-usertype">
                <div className="sub-inv-type">
                  <div
                    style={{ justifyContent: "space-between" }}
                    className={`sb-justify-center-item-center cursor-pointer ${
                      value === "ACCREDITED"
                        ? "radio-active-border"
                        : "radio-border"
                    } `}
                    onClick={() => handleDivClick("ACCREDITED")}
                  >
                    <p className="m-0">Accredited investor</p>
                    <Radio value={"ACCREDITED"}></Radio>
                  </div>
                </div>
                <div className="sub-inv-type">
                  <div
                    style={{ justifyContent: "space-between" }}
                    className={`sb-justify-center-item-center cursor-pointer ${
                      value === "INSTITUTIONAL"
                        ? "radio-active-border"
                        : "radio-border"
                    } `}
                    onClick={() => handleDivClick("INSTITUTIONAL")}
                  >
                    <p className="m-0">Institutional investor</p>
                    <Radio value={"INSTITUTIONAL"}></Radio>
                  </div>
                </div>
              </Row>
            </Radio.Group>
          </div>
        )}

        <div
          className={
            checkStepStatus(user?.waitingVerificationSteps, "QUESTIONNAIRE") ===
              false && user?.waitingVerificationSteps?.length > 0
              ? "p-none sb-checked-div mt-40"
              : "sb-checked-div mt-40"
          }
        >
          <p className="verification-header mt-0 mb-16">
            In the preceding 12 months, you declare you had:
          </p>

          {tasks?.map((task) => (
            <Checkbox
              style={{ marginBottom: 10 }}
              id={task.id}
              checked={task.completed}
              onChange={() => handleCheckboxChange(task.id)}
              key={`beneficialOwner${task.id}`}
              className="checkbox-kilde-new"
            >
              {task.label}
            </Checkbox>
          ))}
          <Checkbox
            checked={lastCheckboxChecked}
            onChange={(e) => handleLastCheckboxChange(e.target.checked)}
            key="last"
            className="checkbox-kilde"
            id="chk-not-investor"
          >
            {value === "ACCREDITED"
              ? "I am not an accredited investor."
              : value === "EXPERT"
              ? "I am not an expert investor."
              : value === "INSTITUTIONAL_INVESTOR"
              ? "I do not represent an institutional investor"
              : "I do not represent an institutional investor"}
          </Checkbox>
        </div>
      </div>
      <Modal
        width={400}
        style={{ padding: 0 }}
        centered
        open={modal2Open}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={() => setModal2Open(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        className="kilde-modal-button warning-modal"
      >
        <div className="not-investormodal" style={{ paddingTop: 10 }}>
          <img src={Alert} alt="alert_img" />
          <div className="child-notinvestor" style={{ padding: 0 }}>
            <p className="sb-verification-title mt-5 mb-10">
              Thanks for your interest!
            </p>
            <p className="kl-subtitle mt-0">
              Kilde is currently for accredited, expert, and institutional
              investors.
            </p>
            <p className="kl-subtitle mt-0">
              Please change your investor status or contact{" "}
              <a
                href="mailto:sales@kilde.sg"
                style={{ color: "var(--kilde-blue)" }}
              >
                sales@kilde.sg
              </a>{" "}
              for more information.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ComonVerification;
