import React from "react";

const EditStratergy = ({ className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2069 3.58561L9.41438 0.792486C9.32152 0.699603 9.21127 0.625923 9.08993 0.575654C8.96859 0.525385 8.83853 0.499512 8.70719 0.499512C8.57585 0.499512 8.4458 0.525385 8.32446 0.575654C8.20312 0.625923 8.09287 0.699603 8 0.792486L0.29313 8.49999C0.199867 8.59251 0.125926 8.70265 0.0756045 8.824C0.025283 8.94535 -0.000414649 9.07549 5.05934e-06 9.20686V12C5.05934e-06 12.2652 0.105362 12.5196 0.292898 12.7071C0.480435 12.8946 0.734789 13 1.00001 13H3.79313C3.9245 13.0004 4.05464 12.9747 4.17599 12.9244C4.29735 12.8741 4.40748 12.8001 4.50001 12.7069L12.2069 4.99999C12.2998 4.90712 12.3734 4.79687 12.4237 4.67553C12.474 4.55419 12.4999 4.42414 12.4999 4.2928C12.4999 4.16146 12.474 4.0314 12.4237 3.91006C12.3734 3.78872 12.2998 3.67847 12.2069 3.58561ZM3.79313 12H1.00001V9.20686L6.50001 3.70686L9.29313 6.49999L3.79313 12ZM10 5.79249L7.20688 2.99999L8.70688 1.49999L11.5 4.29249L10 5.79249Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditStratergy;
