import { Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import DeviceMobile from "../../Assets/Images/Icon2fa.svg";
import OtpInput from "react18-input-otp";
import { enableSMS, getUser, setupSms } from "../../Apis/UserApi.js";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import { Link, useNavigate } from "react-router-dom";
import { setCurrentSate } from "../../Redux/Action/common.js";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../Redux/Action/User.js";
import ROUTES from "../../Config/Routes.js";
import twofaicon from "../../Assets/Images/IconSet2fa.svg";
import twofadone from "../../Assets/Images/twofa-done.svg";
import { showMessageWithCloseIcon, showMessageWithCloseIconError } from "../../Utils/Reusables.js";

const TwoFAModal = ({ twoFaModal, setTwoFaModal, setShouldNavigate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [time, setTime] = useState(59);
  const [setUpSMS, setSetUpSMS] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [otp, setOtp] = useState("");
  const [twoFaLoader, setTwoFaLoader] = useState(false);
  let currentEnv = GlobalVariabels.NODE_ENV;
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const current = useSelector((state) => state.common.current);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      } else {
        console.error("Error fetching data:");
        return null;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTwoFaAuth = async () => {
    setTwoFaLoader(true);
    await setupSms()
      .then((res) => {
        showMessageWithCloseIcon(
          "We've sent an OTP to your mobile number. Please check your messages."
        );
        setIsTimerRunning(true);
        setTime(59);
        setSetUpSMS(res);
        setTwoFaLoader(false);
      })
      .catch(() => {
        setTwoFaLoader(false);
      });
  };

  const handleEnableSMS = async () => {
    setTwoFaLoader(true);
    if (otp !== "") {
      const requestBody = {
        smsToken: otp,
      };
      const response = await enableSMS(requestBody);
      if (!response) {
        window?.dataLayer?.push({
          event: "authenticationTwoFactorConfirm",
        });
        showMessageWithCloseIcon(
          "You've successfully enabled Two-Factor Authentication for your account."
        );
        await getUserDetails();
        setTwoFaLoader(false);
        setSetUpSMS(true);
        setIsTimerRunning(false);
      } else {
        setTwoFaLoader(false);
      }
    } else {
      setTwoFaLoader(false);
      showMessageWithCloseIconError("Please enter OTP!");
    }
  };

  const skipForNow = async () => {
    setTwoFaModal(false);
    if (user?.singpassUser === true) {
      setShouldNavigate(true);
    } else {
      if (user?.investorType === "INDIVIDUAL") {
        setCurrentSate(current + 1, dispatch);
      } else {
        setCurrentSate(2, dispatch);
      }
    }
  };

  const handleContinue = async () => {
    setTwoFaModal(false);
    if (user?.singpassUser === true) {
      if (user?.investorType === "INDIVIDUAL") {
        navigate(ROUTES.INDIVIDUAL_VERIFICATION);
      } else {
        navigate(ROUTES.ORGANIZATION_VERIFICATION);
      }
    } else {
      if (user?.investorType === "INDIVIDUAL") {
        setCurrentSate(current + 1, dispatch);
      } else {
        setCurrentSate(2, dispatch);
      }
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputStyle = {
    borderRadius: "var(--12, 12px)",
    border: "1px solid var(--black-10, rgba(26, 32, 44, 0.10))",
    background: "var(--white-80, rgba(255, 255, 255, 0.80))",
    width: windowWidth <= 380 ? "35px" : "40px",
    marginLeft: "4px",
    marginRight: "4px",
    height: windowWidth <= 380 ? "35px" : "40px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "var(--4, 4px) var(--12, 12px)",
    color: "var(--kilde-blue)",
  };

  return (
    <div>
      <Modal
        open={twoFaModal}
        footer={null}
        onCancel={() => setTwoFaModal(false)}
        maskClosable={false}
        closable={false}
        width={464}
      >
        <div style={{ padding: "20px" }}>
          <div className="kd-2fa-firstdiv">
            {setUpSMS?.phoneNumber ? (
              <img src={twofaicon} alt="2fa" />
            ) : setUpSMS === true ? (
              <img src={twofadone} alt="2fa" />
            ) : (
              <img src={DeviceMobile} alt="2fa" />
            )}

            {setUpSMS?.phoneNumber ? (
              <>
                <p className="sb-TwoFa-title m-0">Enter Authentication Code</p>
                <p className="sb-TwoFa-subtitle m-0">
                  We have sent a code to <b>{user?.mobilePhone}</b>
                </p>
              </>
            ) : setUpSMS === true ? (
              <>
                <p className="sb-TwoFa-title m-0">2FA Setup Completed</p>
                <p className="sb-TwoFa-subtitle m-0">
                  Your account is now more secure. You will receive a
                  verification code via SMS to your number{" "}
                  <b>{user?.mobilePhone}</b> for every login.
                </p>
              </>
            ) : (
              <>
                <p className="sb-TwoFa-title m-0">Two-Factor Authentication</p>
                <p className="sb-TwoFa-subtitle m-0">
                  Two-factor authentication adds an extra layer of security to
                  prevent unauthorized access. Don't wait – activate it today to
                  protect your investments!
                </p>
              </>
            )}
          </div>

          {setUpSMS === false && (
            <p className="sb-TwoFa-title mt-24 mb-24">{user?.mobilePhone}</p>
          )}

          {(setUpSMS === false || setUpSMS === true) && (
            <p
              className={
                setUpSMS === true
                  ? "sb-twofa-link mt-12 mb-24"
                  : "sb-twofa-link mt-0 mb-24"
              }
            >
              To change your account information, contact us at{" "}
              <span>
                <Link to="mailto:sales@kilde.sg">sales@kilde.sg</Link>
              </span>
            </p>
          )}

          {setUpSMS?.phoneNumber !== undefined && (
            <div className="sb-otp-input-div mt-24 mb-24">
              <OtpInput
                value={otp}
                onChange={setOtp}
                inputStyle={inputStyle}
                numInputs={6}
                renderSeparator={<span></span>}
                isInputNum={true}
                inputProps={{ type: "number", inputMode: "numeric" }}
                renderInput={(props) => (
                  <input {...props} type="number" inputMode="numeric" />
                )}
                shouldAutoFocus
              />
            </div>
          )}

          {isTimerRunning && (
            <div className="kd-resend">
              <p className="mt-0 mb-24">
                Resend code in{" "}
                <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>{" "}
              </p>
            </div>
          )}
          {!isTimerRunning && setUpSMS?.phoneNumber !== undefined && (
            <p className="sb-twofa-link mb-24" onClick={handleTwoFaAuth}>
              <Link> Resend Code</Link>
            </p>
          )}

          <div className="sb-TwoFa-actions">
            {currentEnv === "DEV" && setUpSMS?.code && (
              <div>
                <p
                  style={{ textAlign: "right", color: "#ddd" }}
                  className="mt-0 mb-5"
                >
                  Authentication Code: {setUpSMS?.code}
                </p>
              </div>
            )}
            <ButtonDefault
              title={
                setUpSMS?.phoneNumber !== undefined
                  ? "Authenticate"
                  : setUpSMS === true
                  ? "Continue"
                  : "Send Code"
              }
              block={true}
              onClick={
                setUpSMS?.phoneNumber !== undefined
                  ? handleEnableSMS
                  : setUpSMS === true
                  ? handleContinue
                  : handleTwoFaAuth
              }
              loading={twoFaLoader}
            />
            <>
              {user?.secondFactorAuth === null && setUpSMS === false ? (
                <Button
                  block={true}
                  onClick={skipForNow}
                  className="kd-2fa-dothislater"
                >
                  I’ll do this later
                </Button>
              ) : null}
            </>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TwoFAModal;
