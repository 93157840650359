import React from "react";
import CommonFilter from "./CommonFilter";

const FilterComponent = ({ trancheCountry }) => {
  return (
    <div className="filter-desktop-comp">
      <CommonFilter trancheCountry={trancheCountry} />
    </div>
  );
};

export default FilterComponent;
