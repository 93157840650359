import { createSlice } from "@reduxjs/toolkit";

const InvestorInitialState = {
  tranche: [],
  capital: [],

};

const investorSlice = createSlice({
  name: "investor",
  initialState: InvestorInitialState,
  reducers: {
    setTranche: (state, action) => {
      return { ...state, tranche: { ...action.payload } };
    },
    setCapitals: (state, action) => {
      return { ...state, capital: { ...action.payload } };
    },
  },
});

export const { setTranche, setCapitals } = investorSlice.actions;

export default investorSlice.reducer;
