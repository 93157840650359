import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Divider, Form, Input } from "antd";
import InputDefault from "../../Components/InputDefault/InputDefault.jsx";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import ROUTES from "../../Config/Routes.js";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";

import {
  GoogleLoginApiWithCode,
  LoginApi,
  getUser,
} from "../../Apis/UserApi.js";
import Cookies from "js-cookie";
import { redirectToVue } from "../../Utils/Helpers.js";
import { PublicEventApi } from "../../Apis/PublicApi.js";
import { getSystemId, statusCheck } from "../../Apis/InvestorApi.js";
import { setStatusCheckResponse } from "../../Redux/Action/KycIndividual.js";
import { Redirection } from "./Redirection.jsx";
import { useGoogleLogin } from "@react-oauth/google";
import ButtonIcon from "../../Components/ButtonIcon/ButtonIcon.jsx";
import GoogleIcon from "../../Assets/Images/Icons/google_icon.svg";
import { identify, trackEvent } from "../../Utils/Analytics.js";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    email: false,
    password: false,
  });
  const [loader, setLoader] = useState(false);
  const [googleLoader, setGoogleLoader] = useState(false);

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    setValidationErrors({
      email: true,
      password: true,
    });

    if (loginData?.email) {
      const requestBody = {
        email: loginData.email,
        password: loginData.password,
      };

      setLoader(true);

      LoginApi(requestBody)
        .then(async (response) => {
          let regtankStatus;
          if (response?.token) {
            trackEvent("Investor: logged in", { method: "email" });
            Cookies.set("auth_inv_token", response?.token);
            PublicEventApi("loginAccount");
            if (response?.twoFactorAuthenticationRequired === true) {
              navigate(ROUTES.TWO_FA);
              setLoader(false);
            } else {
              getUser()
                .then(async (profileResponse) => {
                  identify(profileResponse);
                  Cookies.set("user", JSON.stringify(profileResponse));
                  if (profileResponse?.investorStatus === "REJECTED") {
                    setLoader(false);
                    return navigate(ROUTES.KILDE_REJECTED_USER);
                  }
                  if (
                    profileResponse?.vwoFeatures?.identityVerificationSystem
                      ?.idvSystemToUse === "regtank"
                  ) {
                    const getSystId = await getSystemId();

                    if (
                      getSystId?.systemId !== null &&
                      getSystId?.systemId !== undefined &&
                      Object.keys(getSystId)?.length > 0
                    ) {
                      regtankStatus = await statusCheck({
                        email: requestBody?.email,
                        systemId: getSystId?.systemId,
                      });
                      await setStatusCheckResponse(regtankStatus, dispatch);
                    }
                    Cookies.set("systemId", getSystId?.systemId);
                  }
                  if (
                    profileResponse?.vwoFeatures.redirectApp?.appToRedirect ===
                    "vue"
                  ) {
                    redirectToVue(
                      profileResponse?.vwoFeatures.redirectApp?.appToRedirect
                    );
                    setLoader(false);
                  } else {
                    Redirection(
                      setLoader,
                      profileResponse,
                      regtankStatus,
                      dispatch,
                      navigate,
                      profileResponse?.vwoFeatures?.redirectApp
                    );
                  }
                })
                .catch((error) => {
                  console.log("profile err", error);
                  setLoader(false);
                });
            }
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
          setLoader(false);
        });
    }
  };

  const googleLoginWithCode = useGoogleLogin({
    onSuccess: async ({ code }) => {
      GoogleLoginApiWithCode(code)
        .then(async (response) => {
          let regtankStatus;
          if (response?.token) {
            trackEvent("Investor: logged in", { method: "google" });
            Cookies.set("auth_inv_token", response?.token);
            PublicEventApi("loginAccount");
            if (response?.twoFactorAuthenticationRequired === true) {
              navigate(ROUTES.TWO_FA);
              setGoogleLoader(false);
            } else {
              await getUser()
                .then(async (profileResponse) => {
                  Cookies.set("user", JSON.stringify(profileResponse));
                  if (profileResponse?.investorStatus === "REJECTED") {
                    setGoogleLoader(false);
                    return navigate(ROUTES.KILDE_REJECTED_USER);
                  }
                  if (
                    profileResponse?.vwoFeatures?.identityVerificationSystem
                      ?.idvSystemToUse === "regtank"
                  ) {
                    const getSystId = await getSystemId();
                    if (
                      getSystId?.systemId !== null &&
                      getSystId?.systemId !== undefined &&
                      Object.keys(getSystId)?.length > 0
                    ) {
                      regtankStatus = await statusCheck({
                        email: profileResponse?.email,
                        systemId: getSystId?.systemId,
                      });
                      await setStatusCheckResponse(regtankStatus, dispatch);
                    }
                    Cookies.set("systemId", getSystId?.systemId);
                  }
                  if (
                    profileResponse?.vwoFeatures.redirectApp?.appToRedirect ===
                    "vue"
                  ) {
                    redirectToVue(
                      profileResponse?.vwoFeatures.redirectApp?.appToRedirect
                    );
                    setGoogleLoader(false);
                  } else {
                    await Redirection(
                      setGoogleLoader,
                      profileResponse,
                      regtankStatus,
                      dispatch,
                      navigate
                    );
                  }
                })
                .catch((error) => {
                  console.log("profile err", error);
                  setGoogleLoader(false);
                });
            }
          } else {
            setGoogleLoader(false);
          }
        })
        .catch((error) => {
          console.error("Error during login:", error);
          setGoogleLoader(false);
        });
    },
    flow: "auth-code",
  });

  return (
    <>
      <AuthLayout>
        <div className="sb-onboarding-form-container">
          <div>
            <p className="kl-pi-title mb-10">Welcome back!</p>
            <p className="kl-subtitle m-0">Login to your Kilde Account</p>
          </div>
          <div className="register-btn-div">
            <div className="login-form-btn-div">
              <div className="sb-flex-column-item-center mt-20 w-100">
                <div className="test-google-css" style={{ width: "100%" }}>
                  <ButtonIcon
                    title="Sign in with Google"
                    icon={<img src={GoogleIcon} alt="google_icon" />}
                    loading={googleLoader}
                    className="google-btn"
                    onClick={() => {
                      setGoogleLoader(true);
                      googleLoginWithCode();
                    }}
                    id="btn-google-login"
                  />
                </div>
              </div>
              {/* <div className="sb-flex-column-item-center mt-20 w-100">
      <ButtonIcon
        title="Sign up with Apple"
        icon={<img src={AppleIcon} alt="AppleIcon" />}
        // loading={appleLoader}
        // onClick={handleAppleSignin}
        style={{ backgroundColor: "#111" }}
        block={true}
      />
    </div> */}
            </div>
          </div>
          <div className="sb-login-actions" style={{ gap: 4 }}>
            <Divider plain>
              <p style={{ color: "#ababab" }}> Or with Email </p>
            </Divider>
          </div>
          <Form
            onFinish={handleLogin}
            name="wrap"
            labelCol={{ flex: "110px" }}
            labelAlign="left"
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <div className="sb-login-form">
              <div>
                <InputDefault
                  placeholder="Email"
                  type="email"
                  onChange={handleChange}
                  name="email"
                  required={true}
                  focusing={validationErrors?.email}
                  validationState={setValidationErrors}
                  errorMsg={"Email is Required"}
                />
              </div>
              <div>
                <Input.Password
                  name="password"
                  placeholder="Password"
                  type="password"
                  style={{
                    height: 40,
                    borderRadius: "12px",
                    border: "1px solid var(--black-10, rgba(26, 32, 44, 0.10))",
                  }}
                  onChange={handleChange}
                  required={true}
                  focusing={validationErrors?.password.toString()}
                  errormsg={"Password is Required"}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <Link className="kl-link " to={ROUTES.FORGOT_PASSWORD}>
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className="mt-30">
              <ButtonDefault
                title="Continue"
                loading={loader}
                style={{ width: "100%" }}
                id="btn-continue-login"
              />
            </div>
          </Form>
          <div className="sb-forgot-new-here">
            <p className="kl-subtitle m-0 register-redirect-link">
              Don't have an account?
              <Link
                className="fp-link"
                to={ROUTES.REGISTER}
                id="link-create-account"
              >
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default LoginPage;
