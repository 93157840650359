import {
  Button,
  Col,
  Divider,
  Progress,
  Row,
  Skeleton,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import GlobalVariabels from "../../../Utils/GlobalVariabels";
import "./style.css";
import ROUTES from "../../../Config/Routes";
import { useNavigate } from "react-router";
import { britishFormatDate, getCountries } from "../../../Utils/Helpers";
import { useSelector } from "react-redux";
import { formatCurrency, getCountryNameByCode } from "../../../Utils/Reusables";

const ManualInvestmentCard = ({ tranchInfo }) => {
  const navigate = useNavigate();
  const countryList = getCountries();
  const user = useSelector((state) => state?.user);
  const [isLoading, setIsLoading] = useState(true);

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
  };
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const cardDesc = tranchInfo?.details?.summary?.shortDescription;

  useEffect(() => {
    const logWidths = () => {
      const manualInvCardDivs = document.querySelectorAll(".manual-inv-card");
      manualInvCardDivs.forEach((manualInvCardDiv) => {
        const divWidth = manualInvCardDiv.offsetWidth;
        const skeletonElements = manualInvCardDiv.querySelectorAll(
          ".ant-skeleton.ant-skeleton-element .ant-skeleton-image"
        );
        skeletonElements.forEach((skeletonElement) => {
          skeletonElement.style.width = divWidth + "px";
          skeletonElement.style.height = "216px";
        });
      });
    };
    logWidths();
    window.addEventListener("resize", logWidths);

    return () => {
      window.removeEventListener("resize", logWidths);
    };
  }, []);

  const handleViewTranche = () => {
    navigate(`${ROUTES.TRANCH_INVEST}/${tranchInfo?.uuid}`);
  };

  return (
    <div className="manual-inv-card">
      {isLoading || tranchInfo?.mainPictureFileReference === null ? (
        <div className="tranch-list-skeleton">
          <Skeleton.Image active={isLoading} />
        </div>
      ) : (
        <div className="p-relative">
          {tranchInfo?.companyLogo !== null ? (
            <div className="financial-logo">
              <img
                src={`${GlobalVariabels.VIEW_IMG}/${tranchInfo?.companyLogo}`}
                alt="borrower_icon"
              />
            </div>
          ) : null}
          {tranchInfo?.mainPictureFileReference ? (
            <img
              onClick={handleViewTranche}
              src={`${GlobalVariabels.VIEW_IMG}/${tranchInfo?.mainPictureFileReference}`}
              alt="card-img"
              className="w-100 tranch-card-mainImg cursor-pointer"
              onLoad={handleImageLoaded}
              onError={handleImageError}
            />
          ) : null}
        </div>
      )}
      <div className="manual-card-bottom-div">
        <div
          className="tranche-card-top cursor-pointer tranche-card-content"
          onClick={handleViewTranche}
        >
          <div>
            <p className="tranche-id m-0">
              {tranchInfo?.trancheNumber === null
                ? "-"
                : tranchInfo?.trancheNumber}
              <br />
              <span className="tranche-name">{tranchInfo?.details?.title}</span>
            </p>
          </div>
          <div style={{ textAlign: "end" }}>
            {tranchInfo?.capitalCallFrequency === 0 ? (
              ""
            ) : (
              <Tag color="default" className="tranche-tag">
                Early redemption
              </Tag>
            )}

            <br />
            <Tag color="default" className="tranche-tag">
              {tranchInfo?.collateral[0] === "Unsecured"
                ? "Unsecured"
                : "Secured"}
            </Tag>
          </div>
        </div>
        {cardDesc === "" ? (
          "-"
        ) : (
          <Row onClick={handleViewTranche} className="cursor-pointer">
            <p className="card-desc">
              {expanded ? cardDesc : `${cardDesc?.slice(0, 100)}`}
              {!expanded && (
                <Button className="expand-btn" onClick={toggleExpand}>
                  ...
                </Button>
              )}
            </p>
          </Row>
        )}
        <div classname="fund-bar">
          <Progress
            percent={tranchInfo?.fundedPercentage}
            type="line"
            strokeColor={"#22b5e9"}
            showInfo={false}
            className="fund-progress"
          />
          <p style={{ textAlign: "end" }} className="mb-16 mt-0 filterData">
            {tranchInfo?.fundedPercentage}% Funded
          </p>
        </div>

        <Divider className="inv-card-divider" />
        <Row onClick={handleViewTranche} className="cursor-pointer mb-16">
          <Col xs={12} lg={12}>
            <p className="mb-5 mt-0 card-info-tag">Country</p>
          </Col>
          <Col xs={12} lg={12} className="sb-text-align-end country-flag">
            {tranchInfo?.countries?.length > 0
              ? tranchInfo?.countries?.map((countryCode, index) => (
                  <Tooltip
                    title={getCountryNameByCode(countryList, countryCode)}
                  >
                    <span
                      style={{
                        filter: "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.20))",
                      }}
                      key={index}
                      className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
                    />
                  </Tooltip>
                ))
              : "-"}
          </Col>
          <Col xs={12} lg={12}>
            <p className="mb-5 mt-0 card-info-tag">Currency</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="mb-5 mt-0 card-val-tag">{tranchInfo?.currencyCode}</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Minimum investment</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {formatCurrency(
                tranchInfo?.currencyCode === "USD"
                  ? "$"
                  : tranchInfo?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                tranchInfo?.nominalValue
              )}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Industry</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {tranchInfo?.trancheType
                ?.toLowerCase()
                .replace(/_/g, " ")
                .replace(/\b\w/g, (c) => c.toUpperCase())}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Collateral</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">{tranchInfo?.collateral[0]}</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Interest Rate</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {formatCurrency("", tranchInfo?.interestRate) + "%"}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Available to invest</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {formatCurrency(
                tranchInfo?.currencyCode === "USD"
                  ? "$"
                  : tranchInfo?.currencyCode === "SGD"
                  ? "S$"
                  : "€",
                tranchInfo?.principalAvailable
              )}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Maturity Date</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {tranchInfo?.maturityDate === null
                ? "-"
                : britishFormatDate(tranchInfo?.maturityDate)}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">Bond Sold</p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {formatCurrency("", tranchInfo?.debenturesSold)}
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-info-tag">
              Early redemption frequency ({tranchInfo?.paymentPeriodInTerms})
            </p>
          </Col>
          <Col xs={12} lg={12}>
            <p className="m-0 mb-5 card-val-tag">
              {tranchInfo?.capitalCallEnabled === false
                ? "No"
                : tranchInfo?.capitalCallFrequency}
            </p>
          </Col>
        </Row>
        <Row className="invest-button-container">
          <Button
            title="Invest"
            style={{
              width: "100%",
              cursor:
                user?.investorStatus !== "ACTIVE" ||
                tranchInfo?.principalAvailable <= 0 ||
                tranchInfo?.trancheStatus !== "ISSUED"
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={
              user?.investorStatus !== "ACTIVE" ||
              tranchInfo?.principalAvailable <= 0 ||
              tranchInfo?.trancheStatus !== "ISSUED"
            }
            className={
              user?.investorStatus !== "ACTIVE" ||
              tranchInfo?.principalAvailable <= 0 ||
              tranchInfo?.trancheStatus !== "ISSUED"
                ? "invest-buttoncard-disabled"
                : "invest-buttoncard"
            }
            onClick={() =>
              navigate(`${ROUTES.TRANCH_INVEST}/${tranchInfo?.uuid}`)
            }
          >
            Invest
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default ManualInvestmentCard;
